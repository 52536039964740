<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
        <el-button v-if="!hideBack" class="right" type="text" @click="$router.back()">返回</el-button>
      </div>
      <el-date-picker v-model="searchForm.month" type="month" :clearable="false" @change="getByAbarnTestingTime"
        class="mgB10 custom-form-input" placeholder="请选择月份">
      </el-date-picker>
      <el-select v-model="searchForm.houseId" placeholder="请选择仓房" @change="getByAbarnTestingTime"
        class="mgL10 mgB10 custom-form-input">
        <el-option v-for="(item,index) in cangList" :key="index" :label="item.houseNo" :value="item.locaId">
        </el-option>
      </el-select>
      <el-select v-model="searchForm.id" placeholder="请选择测温数据" class="mgL10 mgB10 custom-form-input">
        <el-option v-for="(item,index) in tempList" :key="index" :label="item.tempTime" :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
      </el-button>
      <el-button type="info" class="mgL10 mgB10" @click="detailVisible=true">详情</el-button>
      <router-link v-if="tempInfo.id" :to="{
       path: '/temp/pane',
       query: {
         id: tempInfo.id,
         time: tempInfo.tempTime,
         houseNoId: tempInfo.houseNoId
       }
      }">
        <el-button type="success" class="mgL10 mgB10">平面</el-button>
      </router-link>
      <el-button type="warning" class="mgL10 mgB10" @click="openConfig">配置</el-button>
      <div id="clearId" style="text-align: center; border: 1px solid rgb(211, 211, 211);overflow: auto;">
        <canvas id="myCanvas" @click="canvasClick($event)"></canvas>
      </div>
    </el-card>
    <!-- 数据详情 -->
    <el-dialog class="custom-dialog" title="数据详情" :visible.sync="detailVisible" width="520px" :append-to-body="true">
      <TempDetail :tempInfo="tempInfo" />
    </el-dialog>
    <!-- canvas配置 -->
    <el-dialog class="custom-dialog" title="立体设置" :visible.sync="configVisible" width="380px" :append-to-body="true">
      <Config @close="closeConfig" @confirm="changeConfig" />
    </el-dialog>
  </div>
</template>

<script>
  var isRow = false;
  var rows = [];
  var highs = [];
  var b_t = 20, //x起始点 按钮
    y_t = 20, //y起始点
    c_h = 20, //数据显示高
    c_w = 50, //数据显示宽
    f_s = 11, //字体大小
    c_m = 10, //数据边距
    s_t = 0; //x起始点 立方体

  // var eventListener = null/
  import {
    group,
    getColorTemp,
    validateTemp
  } from "@/common/index.js"
  var moment = require('moment');
  export default {
    components: {
      TempDetail: () => import('@/views/temp/components/TempDetail'),
      Config: () => import('@/views/solid/components/Config'),
    },
    data() {
      return {
        detailVisible: false,
        configVisible: false,
        loading: false,
        searchForm: {
          month: new Date(),
          id: "",
          houseId: '',
          high: 0,
          row: 0
        },
        cangList: [],
        tempList: [],
        tempInfo: {},
        hideBack: true
      }
    },
    mounted() {
      this.searchForm.month = moment(this.$route.query.time).format('YYYY-MM')
      let id = this.$route.query.id
      if (id) {
        this.searchForm.id = Number(id)
        this.hideBack = false
      }
      let houseNoId = this.$route.query.houseNoId
      if (houseNoId) this.searchForm.houseId = Number(houseNoId)
      let wareConfig = this.$cookies.get(this.$cache.WARE_CONFIG)
      if (wareConfig) {
        this.changeConfig(JSON.parse(wareConfig));
      } else {
        this.search();
      }
      this.getAbarnNames();
      this.getByAbarnTestingTime()
    },
    methods: {
      //配置切换
      changeConfig(e) {
        c_h = e.height ? e.height : 20 //数据显示高
        c_w = e.width ? e.width : 50 //数据显示宽
        f_s = e.size ? e.size : 11 //字体大小
        c_m = e.margin ? e.margin : 10 //数据边距
        this.search();
      },
      //关闭
      closeConfig() {
        this.configVisible = false
      },
      //打开
      openConfig() {
        this.configVisible = true
      },
      //搜索
      search() {
        if (this.searchForm.id) {
          this.byIdAbarn();
          isRow ? this.solidByIdAbarnRow() : this.solidByIdAbarnHigh()
        }
      },
      //获取仓房信息
      getAbarnNames() {
        this.$axios.Get(this.$api.byCangtypeLiangLocaidNo + '?' + encodeURIComponent("cangType[]") + "=" + [0]
        .toString(), {}).then(res => {
          this.cangList = res.data
        })
      },
      //获取测温数据
      async getByAbarnTestingTime() {
        let res = await this.$axios.Get(this.$api.byAbarnTestingTime, {
          "locaId": this.searchForm.houseId,
          "month": moment(this.searchForm.month).format('YYYY-MM')
        })
        this.tempList = res.data
        // if (fun) fun(res.data)
      },
      //获取仓房信息
      async byIdAbarn() {
        let res = await this.$axios.Get(this.$api.byIdAbarn + this.searchForm.id, {})
        res.data['testingTime'] = res.data.tempTime
        res.data.startPointPosition=3
        this.tempInfo = res.data
      },
      //获取层数据
      solidByIdAbarnHigh() {
        this.loading = true
        this.$axios.Get(this.$api.solidByIdAbarnHigh, {
          high: this.searchForm.high,
          id: this.searchForm.id
        }).then(res => {
          this.canvasRender(res.data, this.tempInfo);
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      //获取行数据
      solidByIdAbarnRow() {
        this.loading = true
        this.$axios.Get(this.$api.solidByIdAbarnRow, {
          row: this.searchForm.row,
          id: this.searchForm.id
        }).then(res => {
          this.canvasRender(res.data, this.tempInfo);
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      //canvas点击
      canvasClick(e) {
        var c = document.getElementById("myCanvas");
        var canvasInfo = c.getBoundingClientRect(),
          t_h = this.tempInfo.row * 1 * (c_h + c_m); //矩形高度;
        var xx = e.clientX - canvasInfo.left,
          yy = e.clientY - canvasInfo.top;
        if (yy <= t_h + y_t + c_h + c_m) {
          for (let i in rows) {
            if (xx <= rows[i].x + c_w && xx >= rows[i].x && yy <= rows[i].y + c_h && yy >= rows[i].y) {
              // this.searchForm.row = this.tempInfo.startPointPosition === 3 || this.tempInfo.startPointPosition === 1 ?
              //   this.tempInfo.row *
              //   1 -
              //   rows[i].row : rows[i].row;
              this.searchForm.row = rows[i].row;
              isRow = true;
              this.solidByIdAbarnRow();
              break;
            }
          }
        } else {
          for (let i in highs) {
            if (xx <= highs[i].x + c_w && xx >= highs[i].x && yy <= highs[i].y + c_h && yy >= highs[i].y) {
              this.searchForm.high = highs[i].high;
              isRow = false;
              this.solidByIdAbarnHigh();
              break;
            }
          }
        }
      },
      //渲染图表
      canvasRender(dataInfo, houseInfo) {
        let than = this;
        rows = [];
        highs=[]
        if (dataInfo.length <= 0) return;
        var t_x = houseInfo.row * 1 * c_m; //矩形偏斜度
        s_t = b_t + c_w + c_m * 2;
        var l_m = s_t + t_x; //矩形距离左边距
        var t_w = houseInfo.col * 1 * (c_w + c_m); //矩形宽
        var t_h = houseInfo.row * 1 * (c_h + c_m); //矩形高度
        var z_h = houseInfo.high * 1 * (c_h + c_m); //长方形高度
        var c = document.getElementById("myCanvas");

        var ctx = c.getContext("2d");
        ctx.clearRect(0, 0, c.width, c.height);
        c.width = t_w + t_x + b_t * 2 + t_x + c_w;
        c.height = t_h + z_h + y_t * 2 + c_h + c_m;


        // ctx.clearRect(0, 0, c.width, c.height);
        ctx.beginPath();
        ctx.moveTo(l_m - c_m, y_t + c_h + c_m + z_h);
        ctx.lineTo(s_t, y_t + c_h + c_m + z_h + t_h);
        ctx.lineTo(s_t + t_w + c_m, y_t + c_h + c_m + z_h + t_h);
        ctx.lineTo(s_t + t_w + t_x, y_t + c_h + c_m + z_h);
        ctx.strokeStyle = "rgb(82, 160, 241)";
        ctx.stroke();
        ctx.closePath()

        ctx.beginPath();
        ctx.moveTo(l_m - c_m, y_t + c_h + c_m); //起始点 x:矩形斜边宽-数据边距 y:y轴开始点+列高+边距
        ctx.lineTo(s_t, y_t + c_h + c_m + t_h);
        ctx.lineTo(s_t + t_w + c_m, y_t + c_h + c_m + t_h);
        ctx.lineTo(s_t + t_w + t_x, y_t + c_h + c_m);
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.fillStyle = "rgb(82, 160, 241)";
        ctx.strokeStyle = "rgb(82, 160, 241)";
        ctx.strokeRect(l_m - c_m, y_t + c_h + c_m, t_w + c_m, z_h);
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.fillStyle = "rgb(82, 160, 241)";
        ctx.strokeStyle = "rgb(82, 160, 241)";
        ctx.strokeRect(s_t, y_t + c_h + c_m + t_h, t_w + c_m, z_h);
        ctx.stroke();
        ctx.closePath();

        if (houseInfo.startPointPosition === 3 || houseInfo.startPointPosition === 2) { //右上下
          for (let i = houseInfo.col - 1; i >= 0; i--) {
            ctx.beginPath();
            ctx.fillStyle = "#8eb9f5";
            ctx.fillRect(l_m + (c_m + c_w) * (houseInfo.col * 1 - i - 1), y_t, c_w, c_h);
            ctx.fill();
            ctx.fillStyle = "#23262E";
            ctx.textAlign = "center";
            ctx.font = f_s + "pt Calibri";
            ctx.textBaseline = "middle";
            ctx.fillText("第" + (i + 1) + "列", l_m + (c_m + c_w) * (houseInfo.col * 1 - i - 1) + c_w / 2, y_t + c_h / 2);
            ctx.closePath()
          }
        } else { //左上下
          for (let i = 0; i < houseInfo.col; i++) {
            ctx.beginPath();
            ctx.fillStyle = "#8eb9f5";
            ctx.fillRect(l_m + (c_m + c_w) * i, y_t, c_w, c_h);
            ctx.fill();
            ctx.fillStyle = "#23262E";
            ctx.textAlign = "center";
            ctx.font = f_s + "pt Calibri";
            ctx.textBaseline = "middle";
            ctx.fillText("第" + (i + 1) + "列", l_m + (c_m + c_w) * i + c_w / 2, y_t + c_h / 2);
            ctx.closePath()
          }
        }

        let lists = group(dataInfo, houseInfo.col);
        if (!isRow) {
          for (let i in lists) {
            let list = lists[i];
            for (let j in list) {
              ctx.fillStyle = getColorTemp(list[j]).bgColor;
              ctx.fillRect(l_m + (c_m + c_w) * j - i * c_m, y_t + c_m + c_h + (c_m + c_h) * i + c_m / 2 + than
                .searchForm.high * (
                  c_m +
                  c_h), c_w, c_h);
              ctx.fill();
              ctx.fillStyle = "#23262E";
              ctx.textAlign = "center";
              ctx.font = f_s + "pt Calibri";
              ctx.fillText(validateTemp(list[j]), l_m + (c_m + c_w) * j + c_w / 2 - i * c_m, y_t + c_m + c_h + (c_m +
                c_h) * i + c_h / 2 + c_m / 2 + than.searchForm.high * (c_m + c_h));
              ctx.closePath()
            }
          }
        } else {
          var y = 0;
          var x = 0;
          if (houseInfo.startPointPosition === 3 || houseInfo.startPointPosition === 1) { //左右下
            x = l_m - c_m * (houseInfo.row * 1 - than.searchForm.row) + c_m;
            y += y_t + c_h + c_m + c_m / 2 + (c_m + c_h) * (houseInfo.row * 1 - than.searchForm.row);
          } else { //左右上
            x = l_m - c_m * than.searchForm.row;
            y = y_t + c_h + c_m + c_m / 2 + (c_m + c_h) * than.searchForm.row;
          }
          for (let i in lists) {
            for (let j in lists[i]) {
              ctx.beginPath();
              ctx.fillStyle = getColorTemp(lists[i][j]).bgColor;
              ctx.fillRect(x + (c_m + c_w) * j, y + (c_m + c_h) * i, c_w, c_h);
              ctx.fill();
              ctx.fillStyle = "#23262E";
              ctx.textAlign = "center";
              ctx.font = f_s + "pt Calibri";
              ctx.fillText(validateTemp(lists[i][j]), x + (c_m + c_w) * j + c_w / 2, y + (c_m + c_h) * i + c_h / 2);
              ctx.closePath()
            }
          }
        }

        if (houseInfo.startPointPosition === 3 || houseInfo.startPointPosition === 1) { //左右下
          for (let i = houseInfo.row - 1; i >= 0; i--) {
            rows.push({
              x: t_x + b_t - c_m * (houseInfo.row * 1 - i),
              y: y_t + c_h + c_m + c_m / 2 + (c_m + c_h) * (houseInfo.row * 1 - i - 1),
              row: i
            });
            ctx.beginPath();
            ctx.lineWidth = 0.5;
            ctx.strokeStyle = isRow && than.searchForm.row === i? "#1E9FFF" : "#23262E";
            ctx.strokeRect(t_x + b_t - c_m * (houseInfo.row * 1 - i), y_t + c_h + c_m + c_m / 2 + (c_m + c_h) * (
              houseInfo.row * 1 - i - 1), c_w, c_h);
            ctx.stroke();
            ctx.fillStyle = isRow && than.searchForm.row === i ? "#1E9FFF" : "#23262E";
            ctx.textAlign = "center";
            ctx.font = f_s + "pt Calibri";
            ctx.fillText("第" + (i + 1) + "行", t_x + b_t + c_w / 2 - c_m * (houseInfo.row * 1 - i), y_t + c_h + c_m +
              c_m / 2 + (c_m + c_h) * (houseInfo.row * 1 - i - 1) + c_h / 2);
            ctx.closePath()
          }
        } else { //左右上
          for (let i = 0; i < houseInfo.row; i++) {
            rows.push({
              x: t_x + b_t - c_m * i,
              y: y_t + c_h + c_m + c_m / 2 + (c_m + c_h) * i,
              row: i
            });
            ctx.beginPath();
            ctx.lineWidth = 0.5;
            ctx.strokeStyle = isRow && than.searchForm.row === i ? "#1E9FFF" : "#23262E";
            ctx.strokeRect(t_x + b_t - c_m * i, y_t + c_h + c_m + c_m / 2 + (c_m + c_h) * i, c_w, c_h);
            ctx.stroke();
            ctx.fillStyle = isRow && than.searchForm.row === i ? "#1E9FFF" : "#23262E";
            ctx.textAlign = "center";
            ctx.font = f_s + "pt Calibri";
            ctx.fillText("第" + (i + 1) + "行", t_x + b_t + c_w / 2 - c_m * i, y_t + c_h + c_m + c_m / 2 + (c_m + c_h) *
              i + c_h / 2);
            ctx.closePath();
          }
        }

        for (let i = 0; i < houseInfo.high; i++) {
          highs.push({
            x: b_t,
            y: y_t + c_h + c_m + t_h + i * (c_m + c_h) + c_m / 2,
            high: i
          });
          ctx.beginPath();
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = !isRow && than.searchForm.high === i ? "#1E9FFF" : "#23262E";
          ctx.strokeRect(b_t, y_t + c_h + c_m + t_h + i * (c_m + c_h) + c_m / 2, c_w, c_h);
          ctx.stroke();
          ctx.fillStyle = !isRow && than.searchForm.high === i ? "#1E9FFF" : "#23262E";
          ctx.textAlign = "center";
          ctx.font = f_s + "pt Calibri";
          // ctx.fillText("第" + (i + 1) + "层", t_w + s_t + c_w + c_w / 2 + c_m, y_t + c_h + c_m + t_h + i * (c_m + c_h) + c_h / 2 + c_m / 2);
          ctx.fillText("第" + (i + 1) + "层", b_t + c_w / 2, y_t + c_h + c_m + t_h + i * (c_m + c_h) + c_h / 2 + c_m / 2);
          ctx.closePath();
        }
      },

    }
  }
</script>

<style scoped>
</style>
